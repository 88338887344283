
import * as nextparkRuntime$EPeh7CVJPV from '/opt/apps/web/utils/getImageProvider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "nextpark",
  "domains": [
    "media.nextpark.dev",
    "media.nextpark.io"
  ],
  "alias": {
    "/npio": "https://media.nextpark.io",
    "/npdev": "https://media.nextpark.dev"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ],
  "quality": 80
}

imageOptions.providers = {
  ['nextpark']: { provider: nextparkRuntime$EPeh7CVJPV, defaults: undefined }
}
        